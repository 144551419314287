import type { NextPage } from 'next';
import { Box } from '@mui/material';

interface Props {
  value: string;
  index: string;
  children: React.ReactNode;
}

const TabPanel: NextPage<Props> = ({ value, index, children }) => {
  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
    >
      {value === index && (
        <Box>
          {children}
        </Box>
      )}
    </div>
  );
};

export default TabPanel;
