import type { NextPage } from 'next';
import Image from 'next/image';
import { Box, Stack, Typography } from '@mui/material';
import { useUiState } from '../../context/uiState';
import databaseSvg from '../../public/images/database-icon.svg';
import errorSvg from '../../public/images/error-icon.svg';

interface Props {
  databaseError: boolean;
  includeSearchHeight: boolean;
}

const FullPageError: NextPage<Props> = ({ databaseError, includeSearchHeight }) => {
  const { searchBarHeight, toolbarHeight} = useUiState();

  const calculateHeight = (): string => {
    if (includeSearchHeight) {
      return `calc(100vh - ${searchBarHeight}px - ${toolbarHeight}px)`;
    } else {
      return `calc(100vh - ${toolbarHeight}px)`;
    }
  };

  return (
    <Stack height={calculateHeight()} alignItems='center' justifyContent='center' className='u-w-100'>
      <Box
        className='u-flex u-flex-justify-center u-flex-align-center u-w-64-px u-h-64-px u-br-50'
        sx={databaseError ? { background: '#D9D9D9', boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)' } : {}}
      >
        {databaseError ? (
          <Image
            src={databaseSvg}
            alt='Database icon'
          />
        ) : (
          <Image
            src={errorSvg}
            alt='Error icon'
          />
        )}
      </Box>
      <Typography className='fs-title-large u-fw-700 u-pt-20'>Loading Error</Typography>
      <Typography className='fs-body-regular'>Oops, sorry, we seem to be having an issue on our end.</Typography>
    </Stack>
  );
};

export default FullPageError;
